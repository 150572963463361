:local(.cnt) {
    background: #fffafa;
    padding: var(--spacing_half);
}

:local(.title) {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing_half);
}

:local(.list),
:local(.form) {
    margin-top: var(--spacing);
}

:local(.form) {
    display: grid;
    gap: var(--spacing);
}

:local(.damage) {
    padding-bottom: var(--spacing_half);
    padding-top: var(--spacing_half);
    font-size: var(--small);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing_half);
    border-bottom: 1px solid #fcc;

    &:last-child {
        border-bottom: 0;
    }


    &:local(.fixed) {
        color: #999;
        :local(.type) {
            text-decoration: line-through;
        }
    }

    :local(.type) {
        font-weight: bold;
    }

    :local(.note) {
        font-size: var(--small);
    }
}