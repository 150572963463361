:local(.cnt) {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    height: 100%;

    :local(.loading) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffffaa;
        position: absolute;
        display: grid;
        align-items: center;
        justify-items: center;
        z-index: 10;
    }
}

:local(.message) {
    text-align: center;
    padding: var(--spacing2x) var(--spacing_half);
    font-size: var(--small);
}



:local(.input) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: var(--spacing_half);
    padding: var(--spacing_quarter);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);
    font-size: var(--big4x);

    input {
        font-size: var(--big2x);
        border: 0;
        outline: none;

        &:hover {
            border: 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    :local(.inputInner) {
        position: relative;
    }

    :local(.scan) {
        position: absolute;
        top: -5px;
        right: 3px;
        font-size: 36px;
    }

    :global(.ant-btn) {
        height: 45px;
    }

}


:local(.main) {
    overflow: scroll;
}

:local(.inputInner) {
    position: relative;
}

:local(.searchAround) {
    position: absolute;
    top: 6px;
    right: 47px;
    font-size: 22px;
}


:local(.scan) {
    position: absolute;
    top: 3px;
    right: 7px;
}

:local(.logTable) {
    margin-top: var(--spacing);
}


:local(.qrlinks) {
    grid-column: 1/span 2;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }
}


:local(.qrlink) {
    padding: var(--spacing_quarter) var(--spacing_half);
    margin-top: 0;
    margin-right: var(--spacing_quarter);
    background: #eee;
    border-radius: 50px;
    color: #000;
    text-decoration: none;
    font-size: var(--small);

}




:local(.modalDevicesAround),
:local(.modalScan) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;

    :local(.closeBtn) {
        background: #ccc;
        display: inline-block;
        margin-bottom: 20px;
        width: 30px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 20px;
        border-radius: 30px;
    }

}

:local(.mqtt_log) {
    display: grid;
    gap: var(--spacing);
    margin: var(--spacing);
    grid-template-columns: 1fr 1fr 1fr;
    align-content: flex-start;
}

:local(.mqtt_log) {
    grid-template-columns: 1fr;
}

:local(.modalDevicesAround) {
    padding: 10px 0px;
    overflow-y: auto;

    :local(.device) {
        padding: var(--spacing_half);
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr auto;

        :local(.last_conn) {
            font-size: var(--small);
        }

        :local(.qr) {
            font-weight: bold;
        }
    }
}