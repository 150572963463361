:local(.document) {
    padding: var(--spacing) 0;
    border-bottom: 1px solid #eee;
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: var(--spacing);
    align-items: center;

    &:last-child {
        border: 0;
    }

    :local(.documentName) {}

    :local(.documentInput) {
        :local(.documentContent) {
            font-weight: 700;
        }

        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        gap: var(--spacing);
    }
}