:local(.webcam) {
  position: fixed;
  z-index: 2000;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  :local(.close) {
    position: absolute;
    z-index: 4000;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    color: #444;
    border: 0;
    border-radius: 100px;
    background: #eee;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  :local(.button) {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 40px);
    border: 0;
    border-radius: 100px;
    font-size: 40px;
    width: 80px;
    height: 80px;
    color: #fff;
    background: #666;
  }
}
