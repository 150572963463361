:local(.cnt) {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: var(--mainColor);
}
:local(.login) {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: var(--spacing2x);
}
:local(.logo) {
  display: block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
  img {
    height: 100px;
  }
}

:local(.login .form) {
  background: #f5f5f5;
  margin: 0px;
  padding: var(--spacing);
  border-radius: var(--spacing_half);
}

@media (max-width: 640px) {
  :local(.cnt) {
    grid-template-columns: 1fr;
  }

  :local(.sidebar) {
    grid-column: 1 / span 1;
  }
}
