:local() {
}

:local(.userPhoto) {
  height: 80px;
  width: 80px;
  background-position: center center;
  background-size: cover;
  border-radius: var(--spacing_quarter);
}
