:local(.cnt) {
  color: #666;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #eee;
  padding: var(--spacing) var(--spacing) var(--spacing) var(--spacing);
  display: grid;

  &:local(.withicon) {
    grid-template-columns: auto 1fr;
    gap: var(--spacing);

    :local(.icon) {
      grid-column: 1 / span 1;
    }

    :local(.info) {
      grid-column: 2 / span 1;
    }
  }
}

:local(.n) {
  font-size: var(--big8x);
  font-weight: bold;
}

:local(.delta),
:local(.totalPercent) {
  font-size: var(--normal);
  margin-left: var(--spacing_half);
  font-weight: bold;

  &:local(.positive) {
    color: #093;
  }

  &:local(.negative) {
    color: #c30;
  }
}

:local(.title) {
  margin-top: 0px;
  font-size: var(--small);
  font-weight: 400;
}

:local(.subtitle) {
  margin-top: -4px;
  font-size: var(--small2x);
  font-weight: 400;
}

:local(.size_small) {
  padding: var(--spacing_half);

  :local(.info) {
    text-align: left;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing_half);
  }

  :local(.n) {
    grid-column: 2 / span 1;
    text-align: right;
    grid-row: 1;
    font-size: var(--small);
  }

  :local(.title) {
    grid-column: 1 / span 1;
    grid-row: 1;
    font-size: var(--small);
  }
}

@media (max-width: 640px) {
  :local(.cnt) {
    padding: var(--spacing_half);
    display: grid;

    &:local(.withicon) {
      grid-template-columns: auto 1fr;
      gap: var(--spacing);
    }
  }

  :local(.n) {
    font-size: var(--big4x);
  }

  :local(.title) {
    font-size: var(--small);
  }
}
