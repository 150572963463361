:local(.subscription) {
  border-bottom: 1px solid #ccc;
  margin-bottom: var(--spacing_half);
  padding: var(--spacing_half);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing);
  position: relative;
  :local(.status) {
    position: absolute;
    font-size: 12px;
    top: var(--spacing);
    right: var(--spacing);
  }
  :local(.user) {
    margin-top: var(--spacing);
  }
}
