:local(.cnt) {
    display: grid;
    grid-template-rows: 32px 1fr;
    grid-template-columns: 2fr 1fr;
    gap: var(--spacing_half);
    height: 100%;
    overflow: hidden;

    @media(max-width:640px) {
        & {
            grid-template-columns: 1fr;
        }
    }
}

:local(.tools) {
    grid-column: 1/span 2;
}

:local(.positions) {
    font-size: 12px;
    padding: var(--spacing_half);
    border: 1px solid var(--gray6);
    height: calc(100% - 32px - var(--spacing_half));
    overflow-y: auto;

    :local(.item) {
        cursor: pointer;
        padding: var(--spacing_quarter);
        gap: var(--spacing_half);
        border-bottom: 1px solid var(--gray6);
        display: grid;
        grid-template-columns: 150px 150px 150px 1fr;

        &.selected {
            background: #eee;
        }

        :local(.date) {}

        :local(.position) {}

        :local(.data) {}
    }
}

:local(.map) {}