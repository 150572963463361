:local(.noNotes) {
  text-align: center;
  margin: 10px;
}

:local(.notes) {
  font-size: 12px;
  display: grid;
  height: 98%;
  overflow: hidden;
  grid-template-rows: auto 1fr;
  gap: var(--spacing_half);

  :local(.filters) {
  }
  :local(.list) {
    overflow-y: scroll;
    :local(.note) {
      padding: var(--spacing) var(--spacing_quarter);
      border-bottom: 1px solid var(--gray6);
      &:last-child {
        border: 0;
      }
      gap: var(--spacing_half);
      display: grid;
      grid-template-columns: 1fr;

      :local(.text) {
        margin-bottom: var(--spacing_half);
        font-weight: 500;
      }
      :local(.date) {
      }
    }
  }
}
