:local(.cnt) {
  :local(.title) {
    font-size: 16px;
    font-weight: 500;
    margin-top: var(--spacing);
    margin-bottom: var(--spacing);
  }

  :local(.tools) {
    padding: 20px 0px;
    text-align: center;

    :global(.btn) {
      cursor: pointer;
      margin-right: 10px;
      display: inline-block;
      padding: var(--spacing_quarter) var(--spacing_half);
      border-radius: var(--spacing_quarter);
    }
  }
}

:local(.theme-light) {
  :global(.btn) {
    background: #eee;
    color: #444;
  }

  :local(.title) {}

  :local(.selected) {
    background: var(--mainColor);
    color: #fff;
  }

}