:local(.input) {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--spacing_half);
  padding: var(--spacing_quarter);
  border-bottom: 1px solid #eee;
  font-size: var(--big4x);

  input {
    font-size: var(--big2x);
    border: 0;
    outline: none;

    &:hover {
      border: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  :local(.inputInner) {
    position: relative;
  }

  :local(.scan) {
    position: absolute;
    top: -5px;
    right: 3px;
    font-size: 36px;
  }

  :global(.ant-btn) {
    height: 45px;
  }
}

:local(.webcam) {
  position: fixed;
  z-index: 4000;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  :local(.camera) {
    position: fixed;
    z-index: 1;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  :local(.close) {
    position: absolute;
    z-index: 4000;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    color: #444;
    border: 0;
    border-radius: 100px;
    background: #eee;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  :local(.torchButton) {
    position: absolute;
    z-index: 100;
    font-size: 30px;
    color: #444;
    background: #eee;
    border-radius: 50px;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    right: 10px;
    bottom: 10px;
  }

  :local(.torchMessage) {
    position: absolute;
    color: #444;
    z-index: 99;
    bottom: 10px;
    padding-left: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    right: 0px;
    line-height: 100%;
    padding-right: 60px;
    left: 0px;
    text-align: right;
    background: #ffffffaa;
  }
}
