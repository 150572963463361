@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mainColor: #a950a9;
  --mainColorTr0: #963d9708;
  --mainColorTr: #963d9716;
  --mainColorTr2: #963d9732;
  --gray1: #333;
  --gray2: #444;
  --gray3: #777;
  --gray4: #999;
  --gray5: #ccc;
  --gray6: #eee;
  --gray7: #f5f5f5;
  --maxWidth: 1300px;
  --antd-wave-shadow-color: var(--mainColor);

  --small2x: 0.7rem;
  --small: 0.8rem;
  --normalsmall: 0.9rem;
  --normal: 1rem;
  --big: 1.2rem;
  --big2x: 1.4rem;
  --big4x: 1.6rem;
  --big8x: 2rem;
  --big16x: 3rem;

  --spacing: 20px;
  --spacing2x: 40px;
  --spacing4x: 80px;
  --spacing_half: 10px;
  --spacing_quarter: 5px;
  --radius: 3px;
  --strong: bold;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.5715;
  margin: 0;
  font-family:
    'Noto Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

.ant-input,
[class^='ant-select'],
[class*=' ant-select'] {
  font-family:
    'Noto Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

a {
  color: var(--mainColor);
  text-decoration: underline;
}

a:hover {
  color: var(--mainColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 640px) {
  .ant-form-item-control {
    margin-bottom: var(--spacing_half);
  }

  .ant-form-item {
    margin-bottom: var(--spacing_half);
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
}

.ant-btn.ant-btn-lg {
  border-radius: 3px;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary:not(:disabled):hover,
.ant-btn-primary:focus {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  border-radius: 3px;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  color: var(--mainColor);
  border-color: var(--mainColor);
  border-radius: 3px;
}

.ant-btn-secondary:disabled {
  color: #ccc;
  border-color: #ccc;
  background: #eee;
  border-radius: 3px;
  cursor: not-allowed;
}

.ant-btn.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}

.ant-btn.ant-btn-dangerous:hover,
.ant-btn.ant-btn-dangerous:focus {
  color: #fff !important;
  border-color: #ff4d4f;
  background: #ff4d4f;
}

.ant-btn-whatsapp,
.ant-btn-whatsapp:hover,
.ant-btn-whatsapp:focus {
  background-color: #07bc4c;
  border-color: #07bc4c;
  color: #fff;
  border-radius: 3px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab:active,
.ant-tabs .ant-tabs-tab:focus {
  color: var(--mainColor);
}

.ant-tabs .ant-tabs-ink-bar {
  background: var(--mainColor);
}

.ant-spin-dot-item {
  background: var(--mainColor) !important;
}

#root {
}

.ant-table.ant-table-small {
  font-size: 12px;
}

.ant-notification {
  z-index: 100000;
}

.ant-select-dropdown {
  z-index: 100000;
}

.ant-form-item-explain-error {
  margin-top: 20px;
}
