:local(.cnt) {
    position: relative;

    :local(.loading) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffffaa;
        position: absolute;
        display: grid;
        align-items: center;
        justify-items: center;
        z-index: 10;
    }
}

:local(.message) {
    text-align: center;
    padding: var(--spacing2x) var(--spacing_half);
    font-size: var(--small);
}

:local(.input) {
    margin-top: var(--spacing);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing_half);
    font-size: var(--big4x);

    input {
        font-size: var(--big2x);
    }
}

:local(.inputInner) {
    position: relative;
}

:local(.searchAround) {
    position: absolute;
    top: 6px;
    right: 47px;
    font-size: 22px;
}

:local(.scan) {
    position: absolute;
    top: 3px;
    right: 7px;
}

:local(.logTable) {
    margin-top: var(--spacing);
}

:local(.qrlinks) {
    font-size: var(--normal);
    padding-top: var(--spacing_half);
    word-break: break-all;
}

:local(.qrlink) {
    padding: var(--spacing_quarter);
}





:local(.modalScan),
:local(.modalDevicesAround) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 1000;

    :local(.closeBtn) {
        background: #ccc;
        display: inline-block;
        margin-bottom: 20px;
        width: 30px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 20px;
        border-radius: 30px;
    }

}

:local(.mqtt_log) {
    display: grid;
    gap: var(--spacing);
    margin: var(--spacing);
    grid-template-columns: 1fr 1fr 1fr;
    align-content: flex-start;
}

@media (max-width: 640px) {
    :local(.input) {
        margin: var(--spacing_half);
        grid-template-columns: 1fr;
    }

    :local(.mqtt_log) {
        grid-template-columns: 1fr;

    }


}

:local(.modalDevicesAround) {
    padding: 10px 0px;
    overflow-y: auto;

    :local(.device) {
        padding: var(--spacing_half);
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr auto;

        :local(.last_conn) {
            font-size: var(--small);
        }

        :local(.qr) {
            font-weight: bold;
        }
    }
}