:local(.device) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    width: 100%;
    gap: var(--spacing_half);
    margin-bottom: var(--spacing2x);
    grid-template-areas: "title title title title"
        "tools tools tools tools"
        "info info info  map"
        "actions actions actions actions"
        "lastTrips lastTrips lastTrips lastTrips"
        "mqtt_commands mqtt_commands mqtt_commands mqtt_commands"
        "events events log log"
        "positions positions positions positions";

    @media(max-width:640px) {
        & {
            grid-template-columns: 1fr;
            grid-template-areas: "title"
                "tools"
                "map"
                "info"
                "actions"
                "mqtt_commands"
                "events"
                "log"
                "positions"
                "lastTrips";
        }
    }
}


:local(.title) {
    grid-area: title;
    display: grid;
    grid-template-columns: 1fr auto auto;
    text-align: center;
    gap: var(--spacing);

    @media(max-width:640px) {
        & {
            text-align: left;
            grid-template-columns: 1fr auto auto auto auto auto;
        }
    }

}

:local(.qr) {
    text-align: left;
}

:local(.legend) {
    font-size: var(--small);

    @media(max-width:640px) {
        & {
            display: none;
        }
    }
}

:local(.tools) {
    grid-area: tools;
    margin-bottom: var(--spacing_half);
}

:local(.info) {
    grid-area: info;

    :local(.table) {
        width: 100%;
        border-collapse: collapse;
        font-size: var(--small);

        td {
            padding: var(--spacing_quarter);
            border-bottom: 1px solid var(--gray6);
        }
    }
}

:local(.battery .info) {
    color: #09D76E;
}

:local(.battery.low) {
    color: rgb(168, 14, 99);
}

:local(.lockStatus),
:local(.connectionStatus) {
    text-align: center;
}

:local(.lockStatus .locked) {
    color: #c30;
}

:local(.lockStatus .unlocked) {
    color: #09D76E;
}

:local(.engineStatus .engineOff) {
    color: #ccc;
}

:local(.engineStatus .engineOn) {
    color: #09D76E;
}

:local(.closeBtn) {
    cursor: pointer;
}

:local(.map) {
    grid-area: map;
    text-align: center;

    img {
        border-radius: var(--spacing_quarter);
    }

    :local(.coordinates) {
        text-align: center;

    }
}

:local(.actions) {
    display: grid;
    gap: var(--spacing);

    :local(.commands) {
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: repeat(8, 1fr);

        @media(max-width:640px) {
            & {
                grid-template-columns: repeat(2, 1fr);
            }
        }

    }

    svg {
        margin-right: var(--spacing_quarter);
    }
}

:local(.colors) {
    line-height: 30px;

    :local(.title) {
        margin-bottom: var(--spacing_half);
    }

    :local(.options) {
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: repeat(11, auto);
        justify-items: center;

        @media(max-width:640px) {
            & {
                gap: 0
            }
        }

        @media(max-width:640px) {
            & {
                grid-template-columns: repeat(6, auto);
            }
        }

        :local(.color) {
            width: 30px;
            height: 30px;

            @media(max-width:640px) {
                & {
                    width: 20px;
                    height: 20px;
                }
            }

            border-radius: 20px;

            &.alert {
                background: linear-gradient(90deg, rgba(204, 51, 0, 1) 0%, rgba(255, 204, 0, 1) 33%, rgba(17, 153, 0, 1) 66%, rgba(0, 51, 255, 1) 100%);
            }

            &.off {
                background: #999;
            }

            &.red {
                background: #f00;
            }

            &.yellow {
                background: #fd0;
            }

            &.orange {
                background: #fa0;
            }

            &.green {
                background: #090;
            }

            &.blue {
                background: #06c;
            }

            &.cyan {
                background: #6cf;
            }

            &.violet {
                background: #93c;
            }

            &.pink {
                background: #f6c;
            }
        }
    }
}

:local(.events) {
    grid-area: events;

    :local(.inner) {
        max-height: 500px;
        overflow-y: auto;
    }
}

:local(.log) {
    grid-area: log;

    :local(.inner) {
        max-height: 500px;
        overflow-y: auto;
    }
}

:local(.positions) {
    grid-area: positions;

    :local(.inner) {
        display: block;
        max-height: 500px;
        height: 100%;
    }

    overflow:hidden;
}

:local(.sectionTitle) {
    font-size: var(--normal);
    font-weight: var(--bold);
    margin-bottom: 10px;
}

:local(.clickLoadArea) {
    border: 1px dashed var(--gray6);
    font-size: var(--small);
    cursor: pointer;
    padding: 20px;
}

:local(.mqtt_commands) {
    display: grid;
    grid-area: mqtt_commands;
    gap: var(--spacing);
    margin: var(--spacing) 0;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: flex-start;

    @media (max-width: 640px) {
        & {
            grid-template-columns: 1fr;

        }


    }

}

:local(.lastTrips) {
    margin-top: var(--spacing);
    grid-area: lastTrips;
}