
:local(.events){
    font-size:12px;
    border:1px solid var(--gray6);
    padding:var(--spacing_half);
    :local(.item){
        padding:var(--spacing_quarter);
        gap:var(--spacing_half);
        display: grid;
        border-bottom: 1px solid var(--gray6);
        grid-template-columns: 150px 1fr;
        :local(.date){
        }
        :local(.type){
        }
        :local(.data){
        }
    }
}
