:local(.renewal) {
  border-bottom: 1px solid #eee;
  padding-bottom: var(--spacing2x);
  display: grid;
  gap: var(--spacing);
  grid-template-areas:
    'info pay_info'
    'payment_records pay_info';
  grid-template-columns: 1fr 400px;
  grid-template-rows: auto auto;
  :local(.info) {
    grid-area: info;
  }
  :local(.pay_info) {
    text-align: right;
    grid-area: pay_info;
  }
  :local(.payment_records) {
    margin-top: var(--spacing_half);
    grid-area: payment_records;
  }
  :local(.status) {
    font-size: 12px;
  }
  :local(.receipts) {
    margin-top: var(--spacing);
    :local(.receipt) {
      align-items: center;
      margin-bottom: var(--spacing);
      padding: var(--spacing_half);
      border-radius: var(--spacing_quarter);
      display: grid;
      grid-template-columns: 1fr auto;
      a {
        vertical-align: middle;
      }
      :local(.amount) {
        font-weight: 700;
      }
    }
    :local(.no_message) {
      text-align: center;
    }
  }
  :local(.form) {
    line-height: 32px;
    margin-top: var(--spacing);
  }
}

:local(.receiptImage) {
  width: 250px;
  height: 250px;
  object-fit: cover;
}
