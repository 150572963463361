:local(.cnt) {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
}

:local(.info) {
    overflow: auto;
}

:local(.mainActions) {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);
    background-color: #f9f9f0;
    padding: var(--spacing_half);

    :local(.mainButton) {
        border-radius: 5px;
        width: 100%;
        padding: var(--spacing_half);
        text-align: center;

        svg {
            margin-right: var(--spacing_half);
        }

        &:local(.unlock) {
            background: var(--mainColor);
            color: #fff;
        }

        &:local(.lock) {
            background: #c30;
            color: #fff;
        }
    }
}

:local(.tabs) {
    height: 100%;

    :global(.ant-tabs-content-holder) {
        overflow: auto;
    }
}

:local(.statusIcons) {
    display: grid;
    margin-bottom: var(--spacing_half);
    grid-template-columns: repeat(4, auto);
    gap: var(--spacing_half);
    text-align: center;
    font-size: var(--big2x);

    :local(.legend) {
        display: none;
    }
}


:local(.battery .info) {
    color: #09D76E;
}

:local(.battery.low) {
    color: rgb(168, 14, 99);
}

:local(.lockStatus),
:local(.connectionStatus) {
    text-align: center;
}

:local(.lockStatus .locked) {
    color: #c30;
}

:local(.lockStatus .unlocked) {
    color: #09D76E;
}

:local(.engineStatus .engineOff) {
    color: #ccc;
}

:local(.engineStatus .engineOn) {
    color: #09D76E;
}


:local(.table) {
    width: 100%;
    border-collapse: collapse;
    font-size: var(--small);

    td {
        padding: var(--spacing_quarter);
        border-bottom: 1px solid var(--gray6);
    }
}


:local(.events) {
    margin: 0 var(--spacing_half);

    :local(.inner) {
        height: 300px;
        overflow-y: hidden;
    }
}

:local(.actions) {
    display: grid;
    gap: var(--spacing);
    margin: var(--spacing_half);

    :local(.commands) {
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: repeat(8, 1fr);

        @media(max-width:640px) {
            & {
                grid-template-columns: repeat(2, 1fr);
            }
        }

    }

    svg {
        margin-right: var(--spacing_quarter);
    }
}


:local(.colors) {
    line-height: 30px;

    :local(.title) {
        margin-bottom: var(--spacing_half);
    }

    :local(.options) {
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: repeat(11, auto);
        justify-items: center;

        @media(max-width:640px) {
            & {
                gap: 0
            }
        }

        @media(max-width:640px) {
            & {
                grid-template-columns: repeat(5, auto);
            }
        }

        :local(.color) {
            width: 30px;
            height: 30px;
            margin-bottom: var(--spacing);

            @media(max-width:640px) {
                & {
                    width: 20px;
                    height: 20px;
                }
            }

            border-radius: 20px;

            &.alert {
                background: linear-gradient(90deg, rgba(204, 51, 0, 1) 0%, rgba(255, 204, 0, 1) 33%, rgba(17, 153, 0, 1) 66%, rgba(0, 51, 255, 1) 100%);
            }

            &.off {
                background: #999;
            }

            &.red {
                background: #f00;
            }

            &.yellow {
                background: #fd0;
            }

            &.orange {
                background: #fa0;
            }

            &.green {
                background: #090;
            }

            &.blue {
                background: #06c;
            }

            &.cyan {
                background: #6cf;
            }

            &.violet {
                background: #93c;
            }

            &.pink {
                background: #f6c;
            }
        }
    }
}

:local(.mqtt_commands) {
    display: grid;
    gap: var(--spacing);
    margin: var(--spacing) 0;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: flex-start;

    @media (max-width: 640px) {
        & {
            grid-template-columns: 1fr;

        }


    }

}

:local(.cntLocationPicker) {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    :local(.cntLocationPickerMap) {}

    :local(.cntLocationPickerButtons) {
        padding: var(--spacing_half);
    }
}