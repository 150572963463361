:local(.metrics) {
  margin: var(--spacing) 0;
}
:local(.metric) {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
  margin-right: var(--spacing);
  padding-bottom: var(--spacing_half);
  :local(.title) {
    padding: var(--spacing_half);
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    font-weight: 700;
    margin-bottom: var(--spacing_half);
  }
  :local(.data) {
    padding: 0 var(--spacing_half);
    font-size: 16px;
    font-weight: 400;
  }
}
