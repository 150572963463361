:local(.timeline) {
    :local(.elements) {
        background: #eee;
        position: relative;
        height: 48px;
        border: 1px #ccc;
        border-style: solid none;
    }

    :local(.controls) {
        padding: var(--spacing_half);
        display: grid;
        gap: var(--spacing_quarter);
        grid-template-columns: auto auto auto auto 1fr;
        align-items: center;
    }
}

:local(.currentTs) {
    display: block;
}

:local(.slider) {}