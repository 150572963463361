:local(.cnt) {
  border: 1px solid var(--gray6);
}

:local(.meta) {
  padding: var(--spacing_half);
  font-size: var(--small);
  word-wrap: break-word;
  padding-bottom: var(--spacing_half);
  border-bottom: 1px solid var(--gray6);
}

:local(.command) {
  display: grid;
  padding: var(--spacing_half);
  gap: var(--spacing_half);
  grid-template-columns: 1fr 60px;
}

:local(.list) {
  font-size: var(--small);
  overflow-y: auto;
}

:local(.item) {
  word-break: break-word;
  padding: var(--spacing_half);
  border-bottom: 1px solid var(--gray6);
  display: grid;
  grid-template-columns: 1fr auto;

  &:last-child {
    border: 0;
  }
}

:local(.time) {
  margin-bottom: 10px;
}

:local(.translation) {
  background: #f5f5f5;
  padding: var(--spacing_half);
}

:local(.message) {
  padding: var(--spacing_half) 0;
}