:local(.deviceSelector) {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
}

:local(.imgField) {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
  max-width: 400px;
  border: 1px solid #ccc;
  padding: 5px;
  text-align: left;
  :local(.fieldLabel) {
    display: grid;
    padding: 5px;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  :local(.capturedPic) {
    width: 100%;
  }
}
